/* eslint-disable quotes */
import bp from './breakpoints';
import { css } from 'styled-components';

const mQueryPoint = {
  mobile: `${bp.mobile}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  tablet: `${bp.tablet}px`,
  desktop: `${bp.desktop}px`,
};

export const bz = `
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  `;

export const boxShadow = `
box-shadow : 0px 3px 6px #00000029;
`
export const font = {
  title:  ` 
            font-size:4.2rem;
            font-family:'Wendy One';
            line-height:normal;
            font-weight:500;
            @media screen and (max-width: ${mQueryPoint.tablet}) {
              font-size: 3.6rem;
            }
          `,

  subtitle: ` 
            font-size:2.4rem;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            
            line-height: 35px;
            letter-spacing: 0.005em;

            @media screen and (max-width: ${mQueryPoint.tablet}) {
              font-size: 2rem;
            }
          `,
  text: ` 
            font-family: Montserrat;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: .1px;
           

            @media screen and (max-width: ${mQueryPoint.tablet}) {
              font-size: 1.6rem;
            }

          `,
  navigationItem:`
            font-family: Montserrat;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
          `,
   messageTop:`
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 22px;
            letter-spacing: 0.05rem;
            
          `,



/*E-COHABITER*/
  h1: `
        font-size: 5rem;
        text-transform: uppercase;
        font-weight:200;
        line-height:30px;
        letter-spacing:1.25px;
        margin:2.4rem 0;

        @media screen and (max-width: ${mQueryPoint.tablet}) {
            font-size: 3.5rem;
        }
    `,

  p:  `
        font-size: 2rem;
        line-height:30px;
        letter-spacing:.5px;
        font-weight:normal;


        @media screen and (max-width: ${mQueryPoint.tablet}) {
            font-size: 1.8rem;
        }
    `, 

    style1:  `
        font-size: 1.8rem;
        line-height:22px;
        text-transform:uppercase;
        letter-spacing:3.6px;
        font-weight:600;
    `,

    styleEquipeMembreTitle:  `
        font-size: 1.8rem;
        line-height:22px;
        text-transform:none;
        letter-spacing:.05em;
        font-weight:700;
    `,

    styleProjetSubTitle:  `
        font-size: 2.2rem;
        line-height:30px;
        text-transform:uppercase;
        letter-spacing:.55px;
        font-weight:600;
    `,

    styleButton:  `
        font-size: 1.8rem;
        line-height:22px;
        text-transform:uppercase;
        letter-spacing:2.7px;
        font-weight:500;
    `,



  button: `
        font-weight: normal;
        font-size: inherit;
    `,
};

export const colors = {

  light: '#fff',
  greyLight: '#CECECE',
  buttonHover: '#6d6967',
  cta:'#57877A',

  dark: '#161616',
  //blue:'#3c8d0a', // green
  blue:'#6a9b4c', // green

  blueLight:'#ebf1e7', // green light
  /*yellow:'#f3e73a', = variante du jaune*/
  yellow:'#ffc010', // orange
  yellowLight:'#F1EFD4',
  grey:'#E8E7E7',



};

export const space = {
  'quarter': '.5rem',
  'half': '1rem',
  'default': '2rem',
  '3': '3rem',
  'double': '4rem',
  '5': '5rem',
  '6': '6rem',
  '7': '7rem',
  'big': '8rem',
  '9': '9rem',
  'huge': '10rem',
}

export const z = {
  modalBackground: `z-index: 100000;`,
  modal: `z-index: 200000;`,
};



// Creates up & down media queries for your breakpoints
// *** Usage ***
// import { mq } from "**/style.js"
// export const StyledComponent = styled.div`
// ${media.tablet`
//   display: flex;
// `}
// ${media.mobile_up`
//   display: none;
// `}
//`

export const mq = Object.keys(bp).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${bp[label]}px) {
      ${css(...args)};
    }
  `;

  acc[`${label}_up`] = (...args) => css`
    @media screen and (min-width: ${bp[label]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});
