import React from 'react';
import styled from 'styled-components';
import {  graphql, useStaticQuery  } from 'gatsby';
import Link from './ExtendedLink';
import {Link as GLink} from 'gatsby';
import { useIntl } from 'react-intl';
import { colors, mq, space, font } from '../consts/style';
import { Text,  Flex } from './Elements';
import { Icon } from '@iconify/react';
/*import instagramIcon from '@iconify/icons-logos/instagram-icon';*/
import instagramIcon from '@iconify/icons-brandico/instagram';
import facebookRect from '@iconify/icons-brandico/facebook-rect';
import pinterestIcon from '@iconify/icons-cib/pinterest';
import Img from 'gatsby-image';
import { FormattedMessage} from 'react-intl'
/*import reglementInterieurFR from '../images/reglement interieur Francais.pdf' 
import reglementInterieurEN from '../images/reglement interieur Anglais.pdf' */
import cgvFR from '../images/Conditions générales de vente Bollène Aventure-FR.pdf' 
import cgvEN from '../images/Conditions générales de vente Bollène Aventure-FR.pdf' 

export const WrapperFooter = styled.div`

  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
  padding-top: 10rem;
  color: white;
  background: ${colors.dark};
  
  justify-content: center;
  align-items: center;
  position:relative;
 
  
`;

const TextFooter = styled(Text)`
 color:white;
 `
const FooterInner = styled.div`
 padding: 0 2rem;
 display:block;
 width: 100%;
 text-align:center;

 ${mq.tabletSmall_up`
   text-align:left;
  display: flex;
  width: 120rem;
  max-width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap:2rem;
  `}
 
 ${mq.tablet_up`
  img {
    display:block;
  }
  `}
  
  ul {
    list-style: circle;
    margin-right:2rem;
    ${mq.tabletSmall`
            margin-right:0;
            list-style: none;
          `}
    padding:0;
      li {
         display:inline-block;
         padding:.5rem;
        ${mq.tabletSmall_up`
            display:block;
             padding:0;
          `}
      a {
        color:${colors.blue};
      }
  }

`;

const FooterRight = styled.div`
  flex-basis: 33.3%;
  flex-grow: 1;
  ${mq.tabletSmall_up` 
  text-align: right;
  `}
 
`;

const FooterLeft = styled.div`
  flex-basis: 33.3%;
  flex-grow: 1;
  line-height:30px;

`;


const LinkSocial = styled.a`
display:flex;
flex-direction:column;
gap:1rem;
align-items:center;
justify-content:center;
`

const FlexIconeWrapper = styled(Flex)`
margin-top:2rem;
margin-bottom:2rem;
align-items:flex-start;
justify-content:flex-start;
gap:${space.default};
${mq.tabletSmall`
  margin-top:1rem;
  margin-bottom:1rem;
  justify-content:center;
`}
`

const FooterBottom = styled.div`
  padding: 0 2rem;
  margin-top:4.4rem;
  a {
     color:${colors.greyLight};
  }
  ${font.text};
  font-size:1.6rem;
  color:white;
  ${mq.tabletSmall` text-align:center;`}
`;


const indexQuery = graphql`
 query logoPaiement {

  
    logoPaiement: file(relativePath: { eq: "logo_paiement.png" }) {
        childImageSharp {
          fixed(width:291, quality:70) {
            ...GatsbyImageSharpFixed
          }
        }
    }
  }
  `
const IconLink = ({to, icon, text}) => {
  return(

    <LinkSocial title={text} href={to} target="_blank"  rel="nofollow noopener noreferrer">
      {icon}
      {text}
    </LinkSocial>
  )
}

const Footer= ({ location }) => {
  const intl = useIntl();
  const today = new Date();
  const year = today.getFullYear();
  const data = useStaticQuery(indexQuery)

  return (
    <WrapperFooter>

    {/*    <BackToTopWrapper>
        <BackToTopLink  to={location.pathname+'#top'} className="anchorlink" title="Retour en haut">
         <ArrowUpIcon width="70px"/>
        </BackToTopLink>
      </BackToTopWrapper> */}
     
      
      <FooterInner>
        <FooterLeft>
        <TextFooter><FormattedMessage id="footer__follow-us"/></TextFooter>
        <FlexIconeWrapper>
          <IconLink to="https://www.facebook.com/bolleneaventure" icon={<Icon title="Facebook" icon={facebookRect} style={{color: '#6a9b4c', fontSize: '58px'}} />} text="Facebook"/>
          <IconLink to="https://www.instagram.com/bollene_aventure" icon={<Icon icon={instagramIcon} style={{color: '#6a9b4c', fontSize: '58px'}} />} text="Instagram"/>
          <IconLink to="https://www.pinterest.fr/bolleneaventure/" icon={<Icon icon={pinterestIcon} style={{color: '#6a9b4c', fontSize: '58px'}} />} text="Pinterest"/>
        </FlexIconeWrapper>
        </FooterLeft>
        <div>
          <TextFooter><FormattedMessage id="footer__liens"/></TextFooter>
          <Flex>
            <ul className="nav_footer_right">
              <li>
                <Link to="/"><FormattedMessage id="homepage"/></Link>
              </li>
              <li>
                <Link to="/nos-activites"><FormattedMessage id="menu__activites"/></Link>
              </li>
              <li>
                <Link to="/groupes"><FormattedMessage id="menu__groupes"/></Link>
              </li>
              <li>
                <Link to="/horaires-tarifs"><FormattedMessage id="menu__horaires-tarifs"/></Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/a-propos"><FormattedMessage id="menu__a-propos"/></Link>
              </li>
              <li>
                <Link to="/acces-contact"><FormattedMessage id="menu__acces-contact"/></Link>
              </li>
              <li>
                <Link to="/decouvrir"><FormattedMessage id="menu__a-decouvrir"/></Link>
              </li>
           { /*  <li>
                <GLink to="/en">EN</GLink> | <GLink to="/">FR</GLink>
              </li>*/}
            </ul>
          </Flex>
        </div>
        <FooterRight>
          <Img fixed={data.logoPaiement.childImageSharp.fixed} alt='paiements acceptés : Carte bancaire, espèce, chèques vacances, chèques'/>
        </FooterRight>
      </FooterInner>
      <FooterBottom>
        © {year} Bollène Aventure - <FormattedMessage id="footer__tel"/> -{" "}
        <Link to="/reglement-interieur">
          <FormattedMessage 
          id="footer__reglement-interieur"/>
        </Link> -{" "}
        <a 
          href={ intl.locale === "fr" ? cgvFR : cgvEN} 
          target='_blank' 
          rel="noreferrer" 
          title="CGV">
          CGV
        </a> -{" "}
        <Link to="/mentions-legales"> <FormattedMessage id="footer__mentions-legales"/></Link> - <Link to="/protection-des-donnees"><FormattedMessage id="footer__data-protection"/></Link>
      </FooterBottom>
    </WrapperFooter>
  );
};

export default Footer;
