import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/wendy-one";
import "@fontsource/kalam/700.css";
/*import "@fontsource/dosis";
import "@fontsource/dosis/200.css";
import "@fontsource/dosis/500.css";
import "@fontsource/dosis/600.css";
import "@fontsource/dosis/700.css";*/
import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

html { font-family: 'Montserrat', sans-serif; }

/*overflow hidden pour ne pas avoir de marge dans swiper full screen (projet.js)*/
html,
body {
 /* overflow-x: hidden;*/
}

@supports (font-variation-settings: normal) {
  html { font-family: 'Montserrat', sans-serif; }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration:none;
	&:hover {
		text-decoration:none;
		color:inherit;
	}
}

::selection {
  background: #E4FAFC; /* WebKit/Blink Browsers */
  color:#161616;
}

/* Pour la page d'accueil : header transparent en mode initial (avant scroll) */

#splash-headroom:not(.headroom--pinned) > div:not(:first-of-type) {
  background-color:transparent;
  box-shadow: none;
  
& svg {
  color: #f2e20f;
  background: #000000b5;
}

}

#splash-headroom:not(.headroom--pinned) li > a {
  color:white;
  text-shadow: 1px 1px black;
}

/* TABLEAU DES TARIFS*/
table {
  width: 100%;
  margin-bottom: 1em;
  border-collapse: collapse;
  background: #ebf1e7;
  border:px solid black;
}

thead th.age {
  white-space: wrap;

  text-align: center;
  color: #ffffff;
  background: #00adbc;
}

td, th {
  font-size:1.4rem;
  padding: .5em .5em;  
}
td {
  border-right: 1px solid #ffffff;
  padding-left: 2rem;
}

tr {
  border-bottom: 1px solid white;
}

th.head_0 {
  /*width: 50%;*/
  font-family: 'Wendy One';
    font-weight: 400;
}
/*
tbody tr:nth-child(2n-1) {
  
  background-color: #e7e7e7;

}*/
tbody th {
  background-color: #6a9b4c;
  color: #fff;
  font-weight:400;
  border-bottom: 1px solid #d3ecf0;
  font-size: 1.4rem;
  line-height: 1.65;
  padding: .75em 1.5em;
}

tbody th p:first-child {
  font-size: 1.6rem;
  text-transform:uppercase;
}



/* Bouton guidap restyling*/
// on vire l'effet survol par defaut
.gdp-scoped-ui .g-button.button:not([disabled]):hover {
  transform: none;
}

.guidap-activity-reserve-button.gdp-scoped-ui .g-button.button.is-medium, .gdp-scoped-ui.guidap-activity-buy-gift-button .g-button.button.is-medium {
  background:#ffc010; //jaune
  font-weight: 700;
    padding: .5rem 2.5rem;
    font-size:18px;
    font-family:Montserrat;
    letter-spacing:0.5px;
    font-weight: 700;
    color: #161616;
    border-color: transparent;
    line-height: 30px;
    white-space:inherit;
    &:hover {
      border-color: transparent;
      color: #fff;
      background:#6a9b4c; //vert
    }
}

`;


export default Global;
