import React from 'react';
import styled from 'styled-components';
import { Download } from '@styled-icons/bootstrap';
import Link from '../ExtendedLink';
import { colors } from '../../consts/style';
import Boop from '../boop';
import { OutboundLink } from "gatsby-plugin-google-gtag";

 const PrimaryLink = styled(Link)`
    display:inline-block;
    border-radius:50px;
    cursor:pointer;
    background:  ${colors.yellow};
    border: none;
    color:  ${colors.dark};
    font-weight:700;
    padding: .5rem 2.5rem;
    margin: 0;
    transition:all .35s ease;
    text-align: center;
    &:hover {
      text-decoration: none;
      background:  ${colors.blue};
      color:white!important;
    /*  border:1px solid ${colors.dark};
      background: transparent;*/
    }
  
`;

const PrimaryTrackingLink = styled(OutboundLink)`
    display:inline-block;
    border-radius:50px;
    cursor:pointer;
    background:  ${colors.yellow};
    border: none;
    color:  ${colors.dark};
    font-weight:700;
    padding: .5rem 2.5rem;
    margin: 0;
    transition:all .35s ease;
    text-align: center;
    &:hover {
      text-decoration: none;
      background:  ${colors.blue};
      color:white!important;
    /*  border:1px solid ${colors.dark};
      background: transparent;*/
    }
  
`;

const PrimaryExternalLink = styled.a`
display:inline-block;
border-radius:50px;
cursor:pointer;
background:  ${colors.yellow};
border: none;
color:  ${colors.dark};
font-weight:700;
padding: .5rem 2.5rem;
margin: 0;
transition:all .35s ease;
text-align: center;
&:hover {
  text-decoration: none;
  background:  ${colors.blue};
  color:white!important;
/*  border:1px solid ${colors.dark};
  background: transparent;*/
}

`;



const BtnPrimary = ({children,  ...props}) => {
  //function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-919879518/dyLmCOPr2c0DEN7-0LYD', 'event_callback': callback }); return false; }
const trackClickEvent = (data) => {
  if (typeof window !== 'undefined' && window.gtag) {
    gtag('event', 'conversion', { 'send_to': 'AW-919879518/dyLmCOPr2c0DEN7-0LYD'});
  }
};
 /*console.log(props)*/
return (
    <Boop scale={1.02} timing={100} >
      
      {props.external ? 
      <PrimaryExternalLink target="blank" rel="nofollow noopener noreferrer" href={props.to} {...props}><Download size="24"/> {children}</PrimaryExternalLink> 
      :  props.trackingTel ? <PrimaryTrackingLink {...props}  onClick={() => trackClickEvent()}>{children}</PrimaryTrackingLink> :      <PrimaryLink {...props}>{children}</PrimaryLink>
    }
    </Boop>
     
)
 }
export default BtnPrimary;
