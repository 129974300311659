import React, {  useState } from 'react';
import styled from 'styled-components';
import Message from './Message';
import { FormattedMessage} from 'react-intl';
import Link from './ExtendedLink';
import { colors, font,  boxShadow, mq , space} from '../consts/style';
import  BtnPrimary from './buttons/ButtonRounded';
import  { LanguageSwitcher }  from './language-switcher';
import {  Flex } from './Elements';
import { headerTypes } from '../types/propTypes';
import { MenuAltRight } from '@styled-icons/boxicons-regular';
import { XCircle } from '@styled-icons/bootstrap';
import { useSpring, animated } from 'react-spring'

import LogoSVG from '../images/logo-bollene-aventure.png'
import Boop from './boop';

const Wrapper = styled.div`
  background-color:${colors.blueLight};
  ${boxShadow};
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  ${mq.tablet`
     padding: 0 .5rem;
    `}
`;

const SubHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background:${colors.blue};
  color:white;
  position:relative;
`;


const LanguageSwitcherWrap = styled.div`
  position:absolute;
  right: 0;
  padding: 0 2rem;
  background-color: white;
  height: 100%;
  ${mq.tablet`
    display:none;
   `}

`;

const SubHeaderMessage = styled.span`
   ${font.messageTop};
   padding:.5rem;
   margin:0 auto;
   text-align:center;
`;

const HeaderInner = styled.div`
  display: flex;
 
  width: 100vw;
  justify-content: space-between;
  align-items: center;
     /* flex-wrap: nowrap;*/
`;



const MainMenu = styled.ul`
  display: none;
  
${mq.tablet_up`
  display: flex;
  list-style: none;
  justify-self: flex-start;
  margin: 0;
  padding:0;
  text-transform: uppercase;
 `}

 
`;


const MenuItem = styled.li`
  position: relative;
  color: ${colors.blue};
  &:hover {
    color: ${colors.buttonHover};
  }
  &:not(:last-child):after {
   content: '';
   
    ${mq.tablet_up` 
     margin-left:1.8rem;
      width:0;  
    `}
    ${mq.desktop_up` 
      margin: 0 1.5rem .35rem 1rem;
      background-color:${colors.yellow};
      width: .5rem;
      height: .5rem;
      display: inline-block;
      border-radius: 1rem;
    `}
  }
     
`;

const LinkHeader = styled(props => <Link {...props} />)`
  position:relative;
  color: ${colors.blue};
  &:hover {
    color: ${colors.blue};

  }

  ${font.navigationItem}

  &:before {
    content: "";
    display: block;
    position: absolute;
      left: ${props => (props.selected ?"50%": 0 )};
    bottom: -.45rem;
    background-color: ${colors.yellow};
    border-radius:3px;
    width: 40px;
    height: 3px;
    opacity: ${props => (props.selected ? 1: 0 )};
    transform:translateX(${props => (props.selected ?"-50%": 0 )});
  }

   &:hover:before,  &.active:before  {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    border-radius:8px;
    bottom: -.45rem;
    background-color: ${colors.yellow};
    width: 40px;
    height: 3px;
    transform:translateX(-50%);
    transition: all 0.25s;   
    opacity: 1;
  }
`;

const LogoLink = styled(LinkHeader)`
  img {
    /*vertical-align: unset;*/
     height:8rem;
  }
  height:100%;
 
   ${mq.mobile`
      img { 
        height:5rem;
        width: auto!important;
      }
    `}

  &:hover:before,  &.active:before 
  {
    content:none;
  }
`;

const BurgerMenu = styled.button`
  position:relative;
  border:0;
  margin-right:1rem;
  display:block;
  
   background-color:transparent;
  &:focus {
    outline:none;
  }
  ${mq.tablet_up` 
   display:none;
 `}
   ${mq.tablet`
     margin-right:0;
         padding: 0;
    `}
`;

const CloseMenuMobile = styled.button`
  position:absolute;
  top:1rem;
  right:1rem;
  display:block;
  border:none;
 & svg { fill:white;background:transparent!important;}
  background:transparent;
  &:focus {
    outline:none;
  }
`;

/*MENU MOBILE*/
const StyledMenuAltRight = styled(MenuAltRight)`
  color:${colors.dark};

`
const StyledXCircle = styled(XCircle)`
  color:${colors.dark};
`


const MenuMobile = styled(animated.div)`
  z-index:5;
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  z-index:10;
  transform: translate3d(0,-100%,0);
`
const ContentMobile = styled(animated.div)`

  height:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  nav {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
`

const ItemMobileNav = styled(animated.div)`
  font-size:3rem;
  padding:.5rem;
  margin-bottom:.5rem;
  letter-spacing:3.6px;
  text-transform:uppercase;
`

const FlexBtnWrapper = styled(Flex)`
margin-top:2rem;
margin-bottom:3rem;
align-items:flex-start;
justify-content:center;
& > * {
  margin:0 ${space.quarter};
}
 ${mq.mobile`
     margin-top:1rem;
margin-bottom:1rem;
    `}
`

export default function Header({ location }) {
 
  const [mobileNavOpen, setMobileNavOpen] = useState(false) // mobile menu closed by default
  const MenuAnimation = useSpring({
  native: true,
  to: { opacity: mobileNavOpen ? 1 : 0, transform: mobileNavOpen ? 'translate3d(0,0,0)' : 'translate3d(0,-100%, 0)', backgroundColor: mobileNavOpen ? colors.dark : '#fff'},
  })
  /**
   * Oftentimes we'll have different UI state
   * based on the router location.  Do it here.
   */
  /*useEffect(() => console.log(location), [location]);*/

  return (
    <> 
    
    <SubHeader>
      <SubHeaderMessage><Message/></SubHeaderMessage>
       {/*<LanguageSwitcherWrap> 
        <LanguageSwitcher location={location}/>
      </LanguageSwitcherWrap>*/} 
     </SubHeader>

    <Wrapper>
      <HeaderInner>
        <LogoLink to="/" title="Retour à l'accueil" >
          <Boop  scale={1.03}  timing={200} >
            <img src={LogoSVG} alt="Logo Bollène Aventure" width='114px' />
          </Boop>
        </LogoLink>
        <MainMenu>
         
          <MenuItem>
            <LinkHeader to="/nos-activites" activeClassName="active"><FormattedMessage id="menu__activites"/></LinkHeader>
          </MenuItem>
          <MenuItem>
            <LinkHeader to="/groupes" activeClassName="active"><FormattedMessage id="menu__groupes"/></LinkHeader>
          </MenuItem>
           <MenuItem>
            <LinkHeader to="/horaires-tarifs" activeClassName="active"><FormattedMessage id="menu__horaires-tarifs"/></LinkHeader>
          </MenuItem>
           <MenuItem>
            <LinkHeader to="/a-propos" activeClassName="active"><FormattedMessage id="menu__a-propos"/></LinkHeader>
          </MenuItem>
          <MenuItem>
            <LinkHeader to="/acces-contact" activeClassName="active"><FormattedMessage id="menu__acces-contact"/></LinkHeader>
          </MenuItem>
          <MenuItem>
            <LinkHeader to="/decouvrir" activeClassName="active"><FormattedMessage id="menu__a-decouvrir"/></LinkHeader>
          </MenuItem>
        </MainMenu>
        <FlexBtnWrapper>
         <BtnPrimary to='/nos-activites'><FormattedMessage id="book"/></BtnPrimary>
         <guidap-cart-button></guidap-cart-button>
        </FlexBtnWrapper>
        <BurgerMenu  onClick={() => {setMobileNavOpen(true)}}
    aria-label="Navigation"><StyledMenuAltRight title="Navigation" size="48"/></BurgerMenu>
      </HeaderInner>
    </Wrapper>

    <MenuMobile style={ MenuAnimation}> 
        <ContentMobile>
        
          <nav>
          
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}}>
             <LinkHeader to="/" ><FormattedMessage id="homepage"/></LinkHeader>
          </ItemMobileNav>
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
             <LinkHeader to="/nos-activites" activeClassName="active"><FormattedMessage id="menu__activites"/></LinkHeader>
          </ItemMobileNav>
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
              <LinkHeader to="/groupes" activeClassName="active"><FormattedMessage id="menu__groupes"/></LinkHeader>
          </ItemMobileNav>
          
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
           <LinkHeader to="/horaires-tarifs" activeClassName="active"><FormattedMessage id="menu__horaires-tarifs"/></LinkHeader>
          </ItemMobileNav>  
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
            <LinkHeader to="/a-propos" activeClassName="active"><FormattedMessage id="menu__a-propos"/></LinkHeader>
          </ItemMobileNav>
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
              <LinkHeader to="/acces-contact" activeClassName="active"><FormattedMessage id="menu__acces-contact"/></LinkHeader> 
          </ItemMobileNav>
          <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
            <LinkHeader to="/decouvrir" activeClassName="active"><FormattedMessage id="menu__a-decouvrir"/></LinkHeader>
          </ItemMobileNav>
         {/*   <ItemMobileNav onClick={() => {setMobileNavOpen(!mobileNavOpen)}} >
             <LanguageSwitcher onClick={() => {setMobileNavOpen(!mobileNavOpen)}}/>
          </ItemMobileNav>*/}
       
             
          </nav>
           <FlexBtnWrapper>
         <BtnPrimary onClick={() => {setMobileNavOpen(!mobileNavOpen)}} to='/nos-activites'><FormattedMessage id="book"/></BtnPrimary>
           <BtnPrimary as="a" href="tel:+33768855950" >07 68 85 59 50</BtnPrimary>    
        </FlexBtnWrapper>
          <CloseMenuMobile 
          aria-label="Fermer"
          onClick={() => {setMobileNavOpen(false)}} 
           ><StyledXCircle size="48"  title="Fermer le menu" /></CloseMenuMobile>
        </ContentMobile>    
      </MenuMobile>  
      </>

  );
}

Header.propTypes = headerTypes;
